body{
    background-color:black;
    margin: 0px;
    padding: 0px;
}

.company-icon{
    display: flex;
    align-items: center; 
    padding: 10px 20px;
    padding-top: 2px;
    margin-left: 4%;
}

span{
    font-size: 45px;
    margin-right: 20%;
    margin-top: -10px;
    padding-top: 0px;
}

img{
    width: 8%;
    margin-top: 2px;
    margin-left: -5%;
}

ul {
    list-style-type: none;
    margin: 0; 
    padding: 0; 
    font-size: 25px;
    width: 80%;
    margin-top: 0.5%;
  }
  
  li {
    display: inline-block; 
    margin-right: 5%; 
  }
  
  a {
    text-decoration: none; 
    color: white;
  }
  
  a:hover {
    color: #555; 
  }
  