.home-container{
    background: url('https://media.discordapp.net/attachments/1207853354429063212/1211122531545907291/Colorful_Culture_and_Communication_Presentation_1.png?ex=65ed0cc1&is=65da97c1&hm=4d417b719a26c1f36bca9ac8fa44febc1ce38c7203cee92f07b3232bda81cb10&=&format=webp&quality=lossless&width=1383&height=778') no-repeat;
    height: 85vh;
    background-position: center;
    background-color:black;
    color: white;
    background-size: cover;
    margin-left: -5%;
    margin-top: -2%;
    font-family: Arial, Helvetica, sans-serif, Verdana, sans-serif;
}

#h3-connect{
    text-align: center;
    text-decoration: underline white;
    font-size: 20px;
    margin-top: 0px;
    margin-left: -5%;
}

.secondary-container{
    margin-top: -36%;
    margin-bottom: 18%;
    color: white;
}

#h1-connect{
    margin-bottom: 0%;
    font-size: 80px;
    text-align: center;
    font-weight: bold;
    margin-left: -5%;
    color: white;
}

#h2-connect{
    margin-top: -15px;
    font-size: 40px;
    font-weight: bold;
    text-align: center;
    margin-left: -5%;
    margin-bottom: 0px;
    color: white;
}

