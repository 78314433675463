header {
  background: black;
  color: white;
  padding: 20px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 8%);
  position: relative;
  z-index: 44;
}
/* nav */
nav ul {
  display: flex;
}
nav a {
  text-transform: capitalize;
  margin-right: 30px;
  color: #fbfbfb;
}
/* User */
.profile {
  margin-left: 30px;
  cursor: pointer;
}
.profile .image {
  display: flex;
  padding-bottom: 20px;
}
.profile .image img {
  margin-right: 20px;
}
.profile button {
  font-size: 17px;
  font-weight: 500;
}
.profile img {
  width: 40px;
  height: 40px;
  margin-right: -90px;
  border-radius: 50%;
  object-fit: cover;
}
.openProfile {
  color: #ffffff;
  box-shadow: 0 2px 4px rgba(189, 174, 174, 0.08);
  position: absolute;
  top: 80px;
  right: 10px;
  width: 250px;
  background: #fff;
}
.openProfile .box {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  padding: 5px;
  transition: 0.5s;
}
.openProfile .icon {
  font-size: 20px;
  margin-right: 20px;
}
.openProfile h4 {
  font-size: 15px;
  font-weight: 500;
}
.openProfile button:hover {
  background: rgba(147, 75, 255, 0.075);
}
header.active {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 0 4px 0 rgba(255, 254, 254, 0.2);
  z-index: 9999;
  background: #1f1e1e;
}
@media screen and (max-width: 768px) {
  header .search {
    display: none;
  }
  nav {
    display: none;
  }
}
